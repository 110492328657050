<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span>
      Are any of the SMA's portfolio managers also portfolio managers for other separately managed accounts or private investment company (hedge
      funds, other types of private partnerships or limited liability companies (collectively, "Other Accounts"))?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_OTHER_ACCOUNTS_Q1')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_ACCOUNTS_Q1')?.reviewed || !!getAnswer('DDQ_OTHER_ACCOUNTS_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_OTHER_ACCOUNTS_Q1'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-margin-bottom">
      If no, please
      <a href="javascript: void(0)" (click)="selectTab()">click here</a>
      to skip to Insurance tab.
    </span>

    <span class="abs-nomargin">
      Have there been any material changes to how your firm monitors and manages potential conflicts of interest that are associated with side-by-side
      management of Other Accounts and the SMA, including:
    </span>
    <ul class="abs-nomargin">
      <li>use of short sales (or differing positions in the same or related securities for different client accounts);</li>
      <li>allocation of IPOs, private placements and other limited availability investment opportunities;</li>
      <li>use of cross trades;</li>
      <li>aggregation and allocation of trades, including partial fills;</li>
      <li>sequential transactions (i.e., transactions for one account followed by related transactions for another account); and</li>
      <li>performance fees?</li>
    </ul>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-margin-half-top">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_OTHER_ACCOUNTS_Q2')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_ACCOUNTS_Q2')?.reviewed || !!getAnswer('DDQ_OTHER_ACCOUNTS_Q2')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_OTHER_ACCOUNTS_Q2', 'DDQ_OTHER_ACCOUNTS_Q2_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_OTHER_ACCOUNTS_Q2_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_ACCOUNTS_Q2_COMMENTS')?.reviewed || !!getAnswer('DDQ_OTHER_ACCOUNTS_Q2_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>
