<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Audits</p>
    <span>Do you have an internal audit department that reviews your firm's internal compliance controls?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_AUDITS_Q1')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_AUDITS_Q1')?.reviewed || !!getAnswer('DDQ_AUDITS_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_AUDITS_Q1', 'DDQ_AUDITS_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_AUDITS_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_AUDITS_Q1_COMMENTS')?.reviewed || !!getAnswer('DDQ_AUDITS_Q1_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>

    <span>Has a third party reviewed your firm's internal compliance controls?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_AUDITS_Q2')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_AUDITS_Q2')?.reviewed || !!getAnswer('DDQ_AUDITS_Q2')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_AUDITS_Q2', 'DDQ_AUDITS_Q2_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-nomargin">
      If yes, please include relevant written report(s) (i.e., SSAE 16, management letter from independent auditor or similar report).
    </span>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_AUDITS_Q2_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_AUDITS_Q2_COMMENTS')?.reviewed || !!getAnswer('DDQ_AUDITS_Q2_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Recordkeeping</p>
    <span>Has your firm retained all required books and records relating to the SMA for the required retention periods?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_RECORDKEEPING_Q1')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_RECORDKEEPING_Q1')?.reviewed || !!getAnswer('DDQ_RECORDKEEPING_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_RECORDKEEPING_Q1', 'DDQ_RECORDKEEPING_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If no, explain."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_RECORDKEEPING_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_RECORDKEEPING_Q1_COMMENTS')?.reviewed || !!getAnswer('DDQ_RECORDKEEPING_Q1_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>
