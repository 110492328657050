<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Investment Personnel</p>
    <span>
      Have there been any material changes (e.g., hirings, departures or transfers) in the key investment personnel (e.g., portfolio managers,
      analysts) who perform services for the SMA?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_INV_PERSONNEL_Q1')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_INV_PERSONNEL_Q1')?.reviewed || !!getAnswer('DDQ_INV_PERSONNEL_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_INV_PERSONNEL_Q1', 'DDQ_INV_PERSONNEL_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-nomargin">
      If yes, describe, including, for new personnel, a summary of their education, experience, and various responsibilities.
    </span>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INV_PERSONNEL_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INV_PERSONNEL_Q1_COMMENTS')?.reviewed || !!getAnswer('DDQ_INV_PERSONNEL_Q1_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>

    <span>
      Have there been any material changes in the manner in which compensation is determined for the person or persons who are primarily responsible
      for the day-to-day management of the Firm's portfolios, including both cash and non-cash remuneration, such as salary, bonus, deferred
      compensation, and pension and retirement plans and arrangements?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_INV_PERSONNEL_Q2')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_INV_PERSONNEL_Q2')?.reviewed || !!getAnswer('DDQ_INV_PERSONNEL_Q2')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_INV_PERSONNEL_Q2', 'DDQ_INV_PERSONNEL_Q2_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INV_PERSONNEL_Q2_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INV_PERSONNEL_Q2_COMMENTS')?.reviewed || !!getAnswer('DDQ_INV_PERSONNEL_Q2_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Investment Process</p>
    <span>
      Is your firm managing the SMA in accordance with the investment objectives, policies, and restrictions contained in the SMA's Trading Manager
      Agreement, including, any rules imposed by regulators in which your firm is registered?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_INV_PROCESS_Q1')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_INV_PROCESS_Q1')?.reviewed || !!getAnswer('DDQ_INV_PROCESS_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_INV_PROCESS_Q1', 'DDQ_INV_PROCESS_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If no, please describe"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INV_PROCESS_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INV_PROCESS_Q1_COMMENTS')?.reviewed || !!getAnswer('DDQ_INV_PROCESS_Q1_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>

    <span>During the last 12 months did the firm report all known compliance violations to the SMA's CCO?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_INV_PROCESS_Q2')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_INV_PROCESS_Q2')?.reviewed || !!getAnswer('DDQ_INV_PROCESS_Q2')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_INV_PROCESS_Q2'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
  </div>
</div>
