<div fxLayout="column" class="form-section" fxLayoutGap="4px">
  <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
    <app-questionnaire-text-answer
      fxFlex="80"
      label="Investment Management Company"
      [data]="getAnswer('DDQ_OVW_COMPANY_NAME')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_COMPANY_NAME')?.reviewed || !!getAnswer('DDQ_OVW_COMPANY_NAME')?.reviewing"
    ></app-questionnaire-text-answer>
    @if (showReview) {
      <app-questionnaire-review
        [disabled]="isReviewed()"
        [answers]="
          getAnswersForReview([
            'DDQ_OVW_SEC_CONTACT_NAME',
            'DDQ_OVW_SEC_CONTACT_TITLE',
            'DDQ_OVW_SEC_CONTACT_PHONE',
            'DDQ_OVW_SEC_CONTACT_EMAIL',
            'DDQ_OVW_PRIM_CONTACT_NAME',
            'DDQ_OVW_PRIM_CONTACT_TITLE',
            'DDQ_OVW_PRIM_CONTACT_PHONE',
            'DDQ_OVW_PRIM_CONTACT_EMAIL',
            'DDQ_OVW_COMPANY_NAME',
            'DDQ_OVW_COMPANY_ADDRESS',
            'DDQ_OVW_COMPANY_CITY',
            'DDQ_OVW_COMPANY_STATE',
            'DDQ_OVW_COMPANY_ZIP',
            'DDQ_OVW_COMPANY_COUNTRY',
            'DDQ_OVW_COMPANY_MAIN_PHONE',
            'DDQ_OVW_COMPANY_WEB_SITE',
          ])
        "
        reviewHandler="OVERVIEW_COMPANY_HANDLER"
        (errorhandler)="onError($event)"
      ></app-questionnaire-review>
    }
  </div>
  <app-questionnaire-text-answer
    style="width: 85%"
    label="Primary Office Address"
    [data]="getAnswer('DDQ_OVW_COMPANY_ADDRESS')"
    (errorhandler)="onError($event)"
    [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_COMPANY_ADDRESS')?.reviewed || !!getAnswer('DDQ_OVW_COMPANY_ADDRESS')?.reviewing"
  ></app-questionnaire-text-answer>

  <div fxLayout="row" fxLayoutGap="16px">
    <app-questionnaire-text-answer
      style="width: 30%"
      label="City"
      [data]="getAnswer('DDQ_OVW_COMPANY_CITY')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_COMPANY_CITY')?.reviewed || !!getAnswer('DDQ_OVW_COMPANY_CITY')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 25%"
      label="State/County/Province"
      [data]="getAnswer('DDQ_OVW_COMPANY_STATE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_COMPANY_STATE')?.reviewed || !!getAnswer('DDQ_OVW_COMPANY_STATE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 10%"
      label="Zip"
      [data]="getAnswer('DDQ_OVW_COMPANY_ZIP')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_COMPANY_ZIP')?.reviewed || !!getAnswer('DDQ_OVW_COMPANY_ZIP')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 25%"
      label="Country"
      [data]="getAnswer('DDQ_OVW_COMPANY_COUNTRY')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_COMPANY_COUNTRY')?.reviewed || !!getAnswer('DDQ_OVW_COMPANY_COUNTRY')?.reviewing"
    ></app-questionnaire-text-answer>
  </div>

  <div fxLayout="row" fxLayoutGap="16px">
    <app-questionnaire-text-answer
      style="width: 20%"
      label="Main Telephone"
      [data]="getAnswer('DDQ_OVW_COMPANY_MAIN_PHONE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_COMPANY_MAIN_PHONE')?.reviewed || !!getAnswer('DDQ_OVW_COMPANY_MAIN_PHONE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 35%"
      label="Company Website"
      [data]="getAnswer('DDQ_OVW_COMPANY_WEB_SITE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_COMPANY_WEB_SITE')?.reviewed || !!getAnswer('DDQ_OVW_COMPANY_WEB_SITE')?.reviewing"
    ></app-questionnaire-text-answer>
  </div>

  <app-questionnaire-text-answer
    style="width: 60%"
    label="Primary Ops Contact"
    [data]="getAnswer('DDQ_OVW_PRIM_CONTACT_NAME')"
    (errorhandler)="onError($event)"
    [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_PRIM_CONTACT_NAME')?.reviewed || !!getAnswer('DDQ_OVW_PRIM_CONTACT_NAME')?.reviewing"
  ></app-questionnaire-text-answer>
  <div fxLayout="row" fxLayoutGap="16px">
    <app-questionnaire-text-answer
      style="width: 40%"
      label="Title"
      [data]="getAnswer('DDQ_OVW_PRIM_CONTACT_TITLE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_PRIM_CONTACT_TITLE')?.reviewed || !!getAnswer('DDQ_OVW_PRIM_CONTACT_TITLE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 20%"
      label="Phone #"
      [data]="getAnswer('DDQ_OVW_PRIM_CONTACT_PHONE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_PRIM_CONTACT_PHONE')?.reviewed || !!getAnswer('DDQ_OVW_PRIM_CONTACT_PHONE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 35%"
      label="Email"
      [data]="getAnswer('DDQ_OVW_PRIM_CONTACT_EMAIL')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_PRIM_CONTACT_EMAIL')?.reviewed || !!getAnswer('DDQ_OVW_PRIM_CONTACT_EMAIL')?.reviewing"
    ></app-questionnaire-text-answer>
  </div>

  <app-questionnaire-text-answer
    style="width: 60%"
    label="Secondary Ops Contact"
    [data]="getAnswer('DDQ_OVW_SEC_CONTACT_NAME')"
    (errorhandler)="onError($event)"
    [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_SEC_CONTACT_NAME')?.reviewed || !!getAnswer('DDQ_OVW_SEC_CONTACT_NAME')?.reviewing"
  ></app-questionnaire-text-answer>
  <div fxLayout="row" fxLayoutGap="16px">
    <app-questionnaire-text-answer
      style="width: 40%"
      label="Title"
      [data]="getAnswer('DDQ_OVW_SEC_CONTACT_TITLE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_SEC_CONTACT_TITLE')?.reviewed || !!getAnswer('DDQ_OVW_SEC_CONTACT_TITLE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 20%"
      label="Phone #"
      [data]="getAnswer('DDQ_OVW_SEC_CONTACT_PHONE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_SEC_CONTACT_PHONE')?.reviewed || !!getAnswer('DDQ_OVW_SEC_CONTACT_PHONE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 35%"
      label="Email"
      [data]="getAnswer('DDQ_OVW_SEC_CONTACT_EMAIL')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_SEC_CONTACT_EMAIL')?.reviewed || !!getAnswer('DDQ_OVW_SEC_CONTACT_EMAIL')?.reviewing"
    ></app-questionnaire-text-answer>
  </div>
</div>
