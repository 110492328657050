<div fxLayout="column" class="form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="abs-bold">Responsible Investing under the United Nations Principles for Responsible Investment</p>
    <span>
      Responsible investment is an approach to investment that explicitly acknowledges the relevance to the investor of Environmental, Social and
      Governance (ESG) factors, and the long-term health and stability of the market as a whole. It recognizes that the generation of long-term
      sustainable returns is dependent on stable, well-functioning and well verned social, environmental and economic systems.
      <a href="https://www.unpri.org/investment-tools/an-introduction-to-responsible-investment" target="_blank">
        https://www.unpri.org/investment-tools/an-introduction-to-responsible-investment
      </a>
    </span>

    <div fxLayout="column" class="abs-margin-half-top">
      @for (question of questions; track question.commentQuestion; let first = $first) {
        @if (question.selectLabel) {
          <span class="question-info">{{ question.selectLabel }}</span>
        }
        @if (question.selectQuestion) {
          <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
            <app-questionnaire-select-answer
              style="width: 100px"
              [data]="getAnswer(question.selectQuestion)"
              datatype="yesNoNotAvailable"
              (errorhandler)="onError($event)"
              [disabled]="isReviewed() || !!getAnswer(question.selectQuestion)?.reviewed || !!getAnswer(question.selectQuestion)?.reviewing"
            ></app-questionnaire-select-answer>
            @if (showReview) {
              <app-questionnaire-review
                [disabled]="isReviewed()"
                [answers]="getAnswersForReview(question.reviewGroup)"
                (errorhandler)="onError($event)"
                reviewHandler="RESP_INVESTING_HANDLER"
              ></app-questionnaire-review>
            }
          </div>
        }
        @if (question.commentQuestion) {
          <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
            @if (question.commentLabel) {
              <span class="question-info">{{ question.commentLabel }}</span>
            }
            @if (showReview && !question.selectQuestion) {
              <app-questionnaire-review
                [disabled]="isReviewed()"
                [answers]="getAnswersForReview(question.reviewGroup)"
                (errorhandler)="onError($event)"
                reviewHandler="RESP_INVESTING_HANDLER"
              ></app-questionnaire-review>
            }
          </div>
        }
        @if (question.commentQuestion) {
          <app-questionnaire-comment-answer
            [noMargin]="true"
            [noLabel]="true"
            [highlightChanges]="true"
            [data]="getAnswer(question.commentQuestion)"
            (errorhandler)="onError($event)"
            [disabled]="isReviewed() || !!getAnswer(question.commentQuestion)?.reviewed || !!getAnswer(question.commentQuestion)?.reviewing"
          ></app-questionnaire-comment-answer>
        }
      }
    </div>
  </div>
</div>

<div fxLayout="column" class="form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span>Minority Business Ownership</span>
    <span class="abs-margin-half-top">
      On occasion, and in regards to Government pension investments, we are asked about our underlying exposure to minority business owners.
      Definitions vary by country and state, for example below is the definition of a "minority business enterprise" here in the state of Connecticut.
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-margin-half-top">
      <app-questionnaire-checkbox-answer
        label='According to the below or similar definition(s), can your firm be classified as a "minority business enterprise?"'
        [data]="getAnswer('DDQ_RESP_INVESTING_Q10')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_RESP_INVESTING_Q10')?.reviewed || !!getAnswer('DDQ_RESP_INVESTING_Q10')?.reviewing"
      ></app-questionnaire-checkbox-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_RESP_INVESTING_Q10'])"
          (errorhandler)="onError($event)"
          reviewHandler="RESP_INVESTING_HANDLER"
        ></app-questionnaire-review>
      }
    </div>
  </div>

  <div class="form-section abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    Sec. 32-9n. Office of Small Business Affairs. (a) There is established within the Department of Economic and Community Development an Office of
    Small Business Affairs. Such office shall aid and encourage small business enterprises, particularly those owned and operated by minorities and
    other socially or economically disadvantaged individuals in Connecticut. As used in this section, "minority" means: (1) Black Americans, including
    all persons having origins in any of the Black African racial groups not of Hispanic origin; (2) Hispanic Americans, including all persons of
    Mexican, Puerto Rican, Cuban, Central or South American, or other Spanish culture or origin, regardless of race; (3) all persons having origins in
    the Iberian Peninsula, including Portugal, regardless of race; (4) women; (5) Asian Pacific Americans and Pacific islanders; or (6) American
    Indians and persons having origins in any of the original peoples of North America and maintaining identifiable tribal affiliations through
    membership and participation or community identification.
  </div>
</div>
