import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DdqAdviserTabBaseComponent } from '../ddq-adviser-tab-base/ddq-adviser-tab-base.component';

@Component({
  selector: 'app-ddq-adviser-firm-info',
  templateUrl: './ddq-adviser-firm-info.component.html',
  styleUrls: ['../ddq-adviser-tab-base/ddq-adviser-tab-base.component.scss'],
})
export class DdqAdviserFirmInfoComponent extends DdqAdviserTabBaseComponent {
  comments = [
    {
      label: `Does your firm have any managed accounts? Is it managed pari passu with the main funds? Do the managed accounts have any potential fee breaks or advantageous liquidity?`,
      name: `DDQ_FIRM_INFO_Q1`,
    },
    {
      label: `Are you ever cross-trading internally between funds, managed accounts, SPVs, etc? If yes, please explain.`,
      name: `DDQ_FIRM_INFO_Q2`,
    },
    {
      label: `Has your firm launched any new funds in the last 12 months? Does your firm run any other structures with overlapping positions (i.e. in a regulated format like a UCITS fund)? If yes, are liquidity terms and fees different from our invested fund? If yes, please explain.`,
      name: `DDQ_FIRM_INFO_Q3`,
    },
    {
      label: `Are there any principal/internal withdrawals planned from the fund in the next year? If yes, please explain.`,
      name: `DDQ_FIRM_INFO_Q4`,
    },
    {
      label: `Please briefly describe the keyman provisions for the firm. Has the firm determined a plan of succession should any principal leave his position? If yes, please explain.`,
      name: `DDQ_FIRM_INFO_Q5`,
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
