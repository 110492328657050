<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span>
      Does your firm have a monitoring policy and procedure to ensure adherence to the firm's gift/entertainment and outside business activities
      policies?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_OTHER_Q1')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q1')?.reviewed || !!getAnswer('DDQ_OTHER_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_OTHER_Q1', 'DDQ_OTHER_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-nomargin">If yes, please describe. If no, please explain.</span>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_OTHER_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q1_COMMENTS')?.reviewed || !!getAnswer('DDQ_OTHER_Q1_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>

    <span>Were there any substantive changes to the Sub-Advisor's proxy voting policy and procedures?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_OTHER_Q2')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q2')?.reviewed || !!getAnswer('DDQ_OTHER_Q2')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_OTHER_Q2'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>

    <span>Does the firm have appropriate systems, processes and controls in place to store and monitor employee emails?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_OTHER_Q3')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q3')?.reviewed || !!getAnswer('DDQ_OTHER_Q3')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_OTHER_Q3'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>

    <span>
      Does the firm have a monitoring policy and procedure to ensure compliance with the reporting requirements of Sections 13(d), 13(g), and 13(h) of
      the 1934 Act or any other reporting requirements imposed by regulators in the countries in which you trade?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_OTHER_Q4')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q4')?.reviewed || !!getAnswer('DDQ_OTHER_Q4')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_OTHER_Q4'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>

    <span>
      Does the firm have policies and procedures to ensure compliance with the SEC's Whistleblower Rule or similar rules imposed by the regulators in
      which your firm is registered?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_OTHER_Q5')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q5')?.reviewed || !!getAnswer('DDQ_OTHER_Q5')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_OTHER_Q5', 'DDQ_OTHER_Q5_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-nomargin">If so, please provide.</span>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_OTHER_Q5_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q5_COMMENTS')?.reviewed || !!getAnswer('DDQ_OTHER_Q5_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>

    <span>
      Are any of the amounts of foreign taxes paid and/or accrued by ABS reflective of a reduced tax rate resulting from a treaty with the U.S.?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-select-answer
        style="width: 100px"
        [data]="getAnswer('DDQ_OTHER_Q6')"
        datatype="yesNoNotAvailable"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q6')?.reviewed || !!getAnswer('DDQ_OTHER_Q6')?.reviewing"
      ></app-questionnaire-select-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_OTHER_Q6', 'DDQ_OTHER_Q6_NO'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-nomargin">If no, do you have a tax reclaim process to retrieve any potential excess withholding?</span>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_OTHER_Q6_NO')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OTHER_Q6_NO')?.reviewed || !!getAnswer('DDQ_OTHER_Q6_NO')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>
