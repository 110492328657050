import { AfterContentChecked, Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DdqAdviserTabBaseComponent } from '../../ddq-adviser-tab-base/ddq-adviser-tab-base.component';

@Component({
  selector: 'app-ddq-adviser-overview-tables',
  templateUrl: './ddq-adviser-overview-tables.component.html',
  styleUrls: ['../../ddq-adviser-tab-base/ddq-adviser-tab-base.component.scss'],
})
export class DdqAdviserOverviewTablesComponent extends DdqAdviserTabBaseComponent implements AfterContentChecked {
  tables = [
    {
      label: `FIRM's 5 Largest Investors by Client Type (i.e. FoF, HNW, etc.) as a % of AUM`,
      answers: 'DDQ_OVW_CLIENT_BRK_LG_INV_TYPE',
      value: 'DDQ_OVW_CLIENT_BRK_LG_INV_VALUE',
      total: 0,
    },
    {
      label: '% of Firm Assets By Client Type (i.e. FoF, HNW, etc.)',
      answers: 'DDQ_OVW_CLIENT_BRK_FIRM_ASSETS_TYPE',
      value: 'DDQ_OVW_CLIENT_BRK_FIRM_ASSETS_VALUE',
      fieldsToAdd: ['DDQ_OVW_CLIENT_BRK_FIRM_ASSETS_TYPE', 'DDQ_OVW_CLIENT_BRK_FIRM_ASSETS_VALUE'],
      total: 0,
    },
    {
      label: '% of co-mingled Fund Assets ABS is invested by Client Type (ie FOF, HNW, etc.)',
      answers: 'DDQ_OVW_CLIENT_BRK_STR_ASSETS_TYPE',
      value: 'DDQ_OVW_CLIENT_BRK_STR_ASSETS_VALUE',
      fieldsToAdd: ['DDQ_OVW_CLIENT_BRK_STR_ASSETS_TYPE', 'DDQ_OVW_CLIENT_BRK_STR_ASSETS_VALUE'],
      total: 0,
    },
  ];

  checkboxes = [
    { label: 'Ownership', name: 'DDQ_OVW_CHANGES_OWNERSHIP' },
    { label: 'Professional Staff', name: 'DDQ_OVW_CHANGES_STAFF' },
    { label: 'Organizational Structure', name: 'DDQ_OVW_CHANGES_STRUCTURE' },
    { label: 'Auditor / Legal Counsel', name: 'DDQ_OVW_CHANGES_AUDITOR' },
    { label: 'Custodian / Prime Broker / Administrator', name: 'DDQ_OVW_CHANGES_ADMINISTRATOR' },
    { label: 'Board of Directors', name: 'DDQ_OVW_CHANGES_DIRECTORS' },
    { label: 'IT/Compliance Consultant', name: 'DDQ_OVW_CHANGES_IT_COMPLIANCE' },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  ngAfterContentChecked(): void {
    this.tables.forEach((table) => {
      table.total = this.getAllAnswers(table.value).reduce((total, item) => total + this.getNumber(item.value), 0);
    });
  }
}
