import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DdqAdviserTabBaseComponent } from '../ddq-adviser-tab-base/ddq-adviser-tab-base.component';

@Component({
  selector: 'app-ddq-adviser-it-cyber',
  templateUrl: './ddq-adviser-it-cyber.component.html',
  styleUrls: ['../ddq-adviser-tab-base/ddq-adviser-tab-base.component.scss'],
})
export class DdqAdviserItCyberComponent extends DdqAdviserTabBaseComponent {
  comments = [
    {
      label: 'What IT improvements have you made in the past 12 months?',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q8',
    },
    {
      label: `Is the Firm's BCP plan tested at least annually? Please describe how plans are tested (in mock environments, in production parallel, or in production environments) and date of last test.`,
      name: 'DDQ_TECH_BUSINESS_CYBER_Q9',
    },
    {
      label: `Please describe the firm's security awareness program for employees regarding Privacy and Information Security/Cybersecurity including any training and frequency.`,
      name: 'DDQ_TECH_BUSINESS_CYBER_Q10',
    },
    {
      label:
        'Has the firm identified any data breach or other cyber security event? Has the firm enacted its incident response or disaster recovery plan? If so, please explain.',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q21',
    },
    {
      label:
        'What are you doing to assess your service providers including (but not limited to) prime brokers, custodians, administrator, and IT provider?',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q11',
    },
    {
      label:
        'Please describe your defense against external security threats. Does the Firm utilize an intrusion detection system to prevent unauthorized access?',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q12',
    },
    {
      label: `Please describe any vulnerability testing done on the firm's IT infrastructure.  By who? When? What were the results?`,
      name: 'DDQ_TECH_BUSINESS_CYBER_Q13',
    },
    {
      label: 'Does the Firm have an information security incident response plan that addresses data breaches and notification? Please describe.',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q14',
    },
    {
      label:
        'Does the Firm employ a system access administration process to configure, approve and revoke user access based on Least Privilege, Need-to-Know and Segregation of Duties, including regular review of such access for appropriateness?',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q15',
    },
    {
      label: 'Does the Firm encrypt all Firm issued laptops, USB devices and mobile devices?',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q16',
    },
    {
      label:
        'Do employees have access to personal email, file sharing sites (e.g. DropBox), and/or third-party social networking sites from their work computers?',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q17',
    },
    {
      label:
        'Is your IT hosted in a cloud environment? If so, is it private or public? Please list all services/companies you are using to host your IT?',
      name: 'DDQ_TECH_BUSINESS_CYBER_Q18',
    },
    {
      label: `Please describe your Work from Home Policy? What percentage of staff is working remotely (part time or full time)?`,
      name: `DDQ_TECH_BUSINESS_CYBER_Q22`,
    },
    {
      label: `Does the firm have an AI (Artificial Intelligence) Policy in place? If so, please provide detail.`,
      name: `DDQ_TECH_BUSINESS_CYBER_Q23`,
    },
    {
      label: `How have you assured the safeguarding of personal private information (PPI) across the firm with regards to all AI usage?`,
      name: `DDQ_TECH_BUSINESS_CYBER_Q24`,
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
