<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Compliance</p>

    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-nomargin-bottom">
      <div>
        <span>Describe the person(s) or group(s) within your firm primarily responsible for the compliance function, including:</span>
        <ul class="abs-nomargin">
          <li>
            a description of the number of persons, their individual backgrounds, their reporting lines, training and conferences they have
            participated in over the last year, and compliance training provided to the firm's staff; and
          </li>
          <li>any material changes to compliance personnel or resources; and</li>
          <li>any third-party compliance consultants or service providers engaged by the firm.</li>
        </ul>
      </div>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_COMPLIANCE_Q1'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_COMPLIANCE_Q1')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_COMPLIANCE_Q1')?.reviewed || !!getAnswer('DDQ_COMPLIANCE_Q1')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Material Compliance Matters</p>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span>
        Have any previously unreported Material Compliance Matters (as defined in Rule 38a-1 under the Investment Company Act of 1940 or regulations
        required by other regulatory authorities in which your firm is registered) occurred at your firm relating to, among other areas:
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="
            getAnswersForReview([
              'DDQ_MAT_COMPLIANCE_Q1',
              'DDQ_MAT_COMPLIANCE_Q2',
              'DDQ_MAT_COMPLIANCE_Q3',
              'DDQ_MAT_COMPLIANCE_Q4',
              'DDQ_MAT_COMPLIANCE_Q5',
              'DDQ_MAT_COMPLIANCE_Q6',
              'DDQ_MAT_COMPLIANCE_Q7',
              'DDQ_MAT_COMPLIANCE_Q8',
              'DDQ_MAT_COMPLIANCE_Q8_COMMENTS',
            ])
          "
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    @for (question of matComplianceQuestions; track question.question) {
      <span>{{ question.label }}</span>
      <app-questionnaire-boolean-answer
        [data]="getAnswer(question.question)"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer(question.question)?.reviewed || !!getAnswer(question.question)?.reviewing"
      ></app-questionnaire-boolean-answer>
    }

    <span class="abs-nomargin">For each "Yes" answer above, describe the matter, including any remedial actions taken.</span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_MAT_COMPLIANCE_Q8_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_MAT_COMPLIANCE_Q8_COMMENTS')?.reviewed || !!getAnswer('DDQ_MAT_COMPLIANCE_Q8_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Changes to Compliance Policies and Procedures</p>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span>
        Have there been any previously unreported material changes to your firm's compliance policies and procedures relating to, among other areas:
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="
            getAnswersForReview([
              'DDQ_COMPLIANCE_POLICY_Q1',
              'DDQ_COMPLIANCE_POLICY_Q2',
              'DDQ_COMPLIANCE_POLICY_Q3',
              'DDQ_COMPLIANCE_POLICY_Q4',
              'DDQ_COMPLIANCE_POLICY_Q5',
              'DDQ_COMPLIANCE_POLICY_Q6',
              'DDQ_COMPLIANCE_POLICY_Q7',
              'DDQ_COMPLIANCE_POLICY_Q8',
              'DDQ_COMPLIANCE_POLICY_Q8_COMMENTS',
            ])
          "
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    @for (question of changesComplianceQuestions; track question.question) {
      <span>{{ question.label }}</span>
      <app-questionnaire-boolean-answer
        [data]="getAnswer(question.question)"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer(question.question)?.reviewed || !!getAnswer(question.question)?.reviewing"
      ></app-questionnaire-boolean-answer>
    }

    <span class="abs-nomargin">
      For each "Yes" answer above, describe the reason for the change and please provide a copy of the revised policy/procedure, including a redline
      copy if available.
    </span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_COMPLIANCE_POLICY_Q8_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_COMPLIANCE_POLICY_Q8_COMMENTS')?.reviewed || !!getAnswer('DDQ_COMPLIANCE_POLICY_Q8_COMMENTS')?.reviewing
      "
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Annual Assessment</p>

    <span>
      Have you provided to us the results of your firm's last annual assessment of its compliance policies and procedures under the Compliance Rule or
      any additional regulatory rules adopted through local regulators in which your firm is registered?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_ANNUAL_ASSESSMENT_Q1')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_ANNUAL_ASSESSMENT_Q1')?.reviewed || !!getAnswer('DDQ_ANNUAL_ASSESSMENT_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_ANNUAL_ASSESSMENT_Q1', 'DDQ_ANNUAL_ASSESSMENT_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-nomargin">If no, provide a full report or summary of the results of the assessment.</span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_ANNUAL_ASSESSMENT_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_ANNUAL_ASSESSMENT_Q1_COMMENTS')?.reviewed || !!getAnswer('DDQ_ANNUAL_ASSESSMENT_Q1_COMMENTS')?.reviewing
      "
    ></app-questionnaire-comment-answer>

    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-nomargin">
      <div>
        <span>
          To the extent not already covered in a report, discuss your firm's process for conducting the annual assessment of the firm's compliance
          policies and procedures under the Compliance Rule or any additional regulatory rules adopted through local regulators in which your firm is
          registered, including:
        </span>
        <ul class="abs-nomargin">
          <li>the time period covered by the review;</li>
          <li>
            an overview of the testing methods used and frequency of testing (including a description of the individuals or entities that conduct the
            testing); and
          </li>
          <li>methods for remediating any findings or material concerns detected by the review</li>
        </ul>
      </div>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_ANNUAL_ASSESSMENT_Q2'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_ANNUAL_ASSESSMENT_Q2')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_ANNUAL_ASSESSMENT_Q2')?.reviewed || !!getAnswer('DDQ_ANNUAL_ASSESSMENT_Q2')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Compliance Testing</p>

    <span>
      Has the process by which you implement and test your compliance policies and procedures in connection with the SMA materially changed (e.g.,
      automated compliance systems, forensic testing)?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_COMPLIANCE_TESTING_Q1')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_COMPLIANCE_TESTING_Q1')?.reviewed || !!getAnswer('DDQ_COMPLIANCE_TESTING_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_COMPLIANCE_TESTING_Q1', 'DDQ_COMPLIANCE_TESTING_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_COMPLIANCE_TESTING_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_COMPLIANCE_TESTING_Q1_COMMENTS')?.reviewed || !!getAnswer('DDQ_COMPLIANCE_TESTING_Q1_COMMENTS')?.reviewing
      "
    ></app-questionnaire-comment-answer>
  </div>
</div>
