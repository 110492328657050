<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span>
      Have there been any significant events affecting your firm's operations (e.g., mergers, acquisitions, joint ventures, third-party investments,
      executive management changes, introduction or termination of a business line, other new products or services)?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_ORGANIZATION_Q1')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_ORGANIZATION_Q1')?.reviewed || !!getAnswer('DDQ_ORGANIZATION_Q1')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_ORGANIZATION_Q1', 'DDQ_ORGANIZATION_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_ORGANIZATION_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_ORGANIZATION_Q1_COMMENTS')?.reviewed || !!getAnswer('DDQ_ORGANIZATION_Q1_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>

    <span>Have there been any financial solvency issues?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_ORGANIZATION_Q1A')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_ORGANIZATION_Q1A')?.reviewed || !!getAnswer('DDQ_ORGANIZATION_Q1A')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_ORGANIZATION_Q1A', 'DDQ_ORGANIZATION_Q1A_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_ORGANIZATION_Q1A_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_ORGANIZATION_Q1A_COMMENTS')?.reviewed || !!getAnswer('DDQ_ORGANIZATION_Q1A_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>

    <span>Do you have a succession plan in place for key personnel at the firm?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_ORGANIZATION_Q4')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_ORGANIZATION_Q4')?.reviewed || !!getAnswer('DDQ_ORGANIZATION_Q4')?.reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['DDQ_ORGANIZATION_Q4', 'DDQ_ORGANIZATION_Q4_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_ORGANIZATION_Q4_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_ORGANIZATION_Q4_COMMENTS')?.reviewed || !!getAnswer('DDQ_ORGANIZATION_Q4_COMMENTS')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>
